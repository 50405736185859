import { Flex, List, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { API } from '../../../api';
import { LocaleKeys } from '../../../locale';
import { TInvoice } from '../../../types/finance';
import {
  Button,
  ContentInvoice,
  ListItem,
  PlanName,
  Text,
  Wrapper,
} from './InvoiceList.styled';

export const InvoiceList = () => {
  const [loading, setLoading] = useState(false);
  const [invoiceList, setInvoiceList] = useState<TInvoice[] | []>([]);

  const { t } = useTranslation([LocaleKeys.FINANCE]);

  const getTariffPlans = useCallback(async () => {
    try {
      setLoading(true);
      const response = await API.payment.getInvoiceListAsync();
      if (response.status === 200 || response.status === 201) {
        setInvoiceList(response.data);
      } else throw new Error();
    } catch (err) {
      message.error(t('getPlans.errorGetPlans'));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getTariffPlans();
  }, [getTariffPlans]);

  const payment = useCallback((invoice: TInvoice) => {
    window.location.href = invoice.payment_url;
  }, []);

  return (
    <Wrapper>
      <List loading={loading}>
        {invoiceList.map((invoice: TInvoice) => (
          <ListItem key={invoice.billing_invoice_id}>
            <ContentInvoice>
              <Flex vertical gap={10}>
                <Text>Счет №{invoice.payment_invoice_id}</Text>
                <Flex gap={6}>
                  <PlanName> {invoice.pay_amount}₽</PlanName>
                  <Text>{t(`plan.${invoice.plan_name}`)}</Text>
                </Flex>
              </Flex>
              <Text>{invoice.invoice_date}</Text>
            </ContentInvoice>
            <Button onClick={() => payment(invoice)}>
              {t('tariffTextConstants.pay')}
            </Button>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};
