import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import App from 'antd/lib/app';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { API } from '../api';
import { FinanceHeader } from '../components/finance/finance-header';
import { FinancePlanPicker } from '../components/finance/finance-plan-picker';
import { FinanceTabs } from '../components/finance/finance-tabs';
import { FinanceTariffPlans } from '../components/finance/finance-tariff-plans';
import { InvoiceList } from '../components/finance/invoice-list';
import { LocaleKeys } from '../locale';
import { RequestSendSuccessModal } from '../modals/finance/RequestSendSuccess';
import { SuccessPaymentModal } from '../modals/finance/SuccesPayment';
import { FinanceReportModal } from '../modals/finance/finance-report-modal';
import { FinanceSendContacts } from '../modals/finance/finance-send-contacts';
import { LegalFormModal } from '../modals/finance/legal-form-modal';
import { SelectPaymentMethodModal } from '../modals/finance/select-payment-method-modal';
import { useAppDispatch, useAppSelector } from '../state';
import {
  setTariffPlansListAction,
  setTariffPlansListLoaded,
  setTariffPlansListLoading,
} from '../state/finance/getTariffPlansSlice';
import { selectListTariffPlansLoading } from '../state/finance/selectors';
import {
  EFinancePeriod,
  EFinanceTab,
  EPaymentMethod,
  EPlanName,
  TSelectedPlan,
} from '../types/finance';
import { useSearchParams } from '../utils/useSearchParams';

const WrapperPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1920px;
`;

const LoadingWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const currentPeriod = EFinancePeriod.MONTH;

export const FinancePage: FC = () => {
  const { t } = useTranslation([LocaleKeys.FINANCE]);

  const loading = useAppSelector(selectListTariffPlansLoading);

  const [selectedTariffPlan, setSelectedTariffPlan] = useState<
    TSelectedPlan | undefined
  >(undefined);

  const dispatch = useAppDispatch();

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [legalFormModalOpen, setLegalFormModalOpen] = useState(false);
  const [financeReportModalOpen, setFinanceReportModalOpen] = useState(false);
  const [sendContactsModalOpen, setSendContactsModalOpen] = useState(false);
  const [isLoadingPaymentPlan, setIsLoadingPaymentPlan] = useState(false);
  const [isOpenSuccessPaymentModal, setIsOpenSuccessPaymentModal] =
    useState(false);

  const [isOpenSendSuccesRequestModal, setIsOpenSendSuccesRequestModal] =
    useState(false);

  const { message } = App.useApp();

  const { query, setParams } = useSearchParams();

  useEffect(() => {
    if (!query.tab) {
      setParams({ tab: EFinanceTab.PLANS, period: EFinancePeriod.MONTH });
      return;
    }

    if (query.tab === EFinanceTab.INVOICE) {
      const { period, ...updatedQuery } = query;
      setParams(updatedQuery);
      return;
    }

    if (query.tab === EFinanceTab.PLANS && !query.period) {
      setParams({ ...query, period: EFinancePeriod.MONTH });
    }
  }, [query.tab, query.period]);

  const onPeriodChange = useCallback(
    (value: EFinancePeriod) => {
      setParams({ ...query, period: value });
    },
    [query, setParams]
  );

  const isSelectedPlan = Boolean(localStorage.getItem('selectedPlan'));

  useEffect(() => {
    if (query.success_payload === 'true' && isSelectedPlan)
      setIsOpenSuccessPaymentModal(true);
    else setIsOpenSuccessPaymentModal(false);
  }, [isSelectedPlan, query.success_payload]);

  const selectNewTariffPlan = useCallback(
    (plan: TSelectedPlan) => {
      setSelectedTariffPlan(plan);

      if (plan.name === EPlanName.CORP) {
        setSendContactsModalOpen(true);
      } else {
        setPaymentModalOpen(true);
      }
    },
    [query.period]
  );

  const onSelectPaymentMethod = useCallback(
    (method: EPaymentMethod) => {
      if (method === EPaymentMethod.Legal && selectedTariffPlan) {
        setLegalFormModalOpen(true);
      }
    },
    [selectedTariffPlan, query.period]
  );

  const handleGoBackToLegalEdit = useCallback(() => {
    setLegalFormModalOpen(true);
  }, []);

  const handleShowReport = useCallback(() => {
    setFinanceReportModalOpen(true);
  }, []);

  const getTariffPlans = useCallback(async () => {
    try {
      if (query.period) {
        dispatch(setTariffPlansListLoading(true));
        const response = await API.payment.getTariffPlansAsync(
          query.period as string
        );
        if (response.status === 200 || response.status === 201) {
          dispatch(setTariffPlansListAction(response.data));
        } else throw new Error();
      }
    } catch (err) {
      message.error(t('getPlans.errorGetPlans'));
    } finally {
      dispatch(setTariffPlansListLoaded(true));
    }
  }, [query.period]);

  useEffect(() => {
    getTariffPlans && getTariffPlans();
  }, [getTariffPlans]);

  const buyTariffPlan = useCallback(async () => {
    try {
      setIsLoadingPaymentPlan(true);
      if (selectedTariffPlan) {
        const response = await API.payment.buyTariffPlanAsync(
          selectedTariffPlan?.id
        );
        if (response.status === 200 || response.status === 201) {
          window.location.href = response.data.payment_url;
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      if (
        err?.response?.data?.message.includes(
          'You already connected to this tariff plan'
        )
      )
        message.error(t('paymnetPlan.errorTariffConnected'));
      else message.error(t('paymnetPlan.errorPayment'));
    } finally {
      setPaymentModalOpen(false);
      setIsLoadingPaymentPlan(false);
    }
  }, [selectedTariffPlan]);

  const renderFinanceContent = useMemo(() => {
    switch (query.tab) {
      case EFinanceTab.PLANS: {
        return (
          <React.Fragment>
            <FinancePlanPicker
              period={query.period as EFinancePeriod}
              onPeriodChange={onPeriodChange}
              t={t}
            />
            {!loading ? (
              <FinanceTariffPlans
                period={query.period as EFinancePeriod}
                selectNewTariffPlan={selectNewTariffPlan}
                t={t}
              />
            ) : (
              <LoadingWrapper>
                <LoadingOutlined />
              </LoadingWrapper>
            )}
          </React.Fragment>
        );
      }
      case EFinanceTab.INVOICE: {
        return <InvoiceList />;
      }
    }
  }, [query.tab, query.period]);

  return (
    <WrapperPage>
      <FinanceHeader currentType={currentPeriod} t={t} />
      <FinanceTabs t={t} />
      <WrapperContent>{renderFinanceContent}</WrapperContent>
      <SelectPaymentMethodModal
        open={paymentModalOpen}
        setOpen={setPaymentModalOpen}
        onNextStep={onSelectPaymentMethod}
        t={t}
        buyTariffPlan={buyTariffPlan}
        isLoadingPaymentPlan={isLoadingPaymentPlan}
      />
      <LegalFormModal
        open={legalFormModalOpen}
        setOpen={setLegalFormModalOpen}
        showReport={handleShowReport}
        t={t}
      />
      <FinanceReportModal
        setOpen={setFinanceReportModalOpen}
        open={financeReportModalOpen}
        backToEdit={handleGoBackToLegalEdit}
        t={t}
      />
      <FinanceSendContacts
        open={sendContactsModalOpen}
        setOpen={setSendContactsModalOpen}
        t={t}
        setOpenSendSuccessRequestModal={setIsOpenSendSuccesRequestModal}
      />

      {isOpenSuccessPaymentModal && (
        <SuccessPaymentModal
          open={isOpenSuccessPaymentModal}
          setOpen={setIsOpenSuccessPaymentModal}
          t={t}
        />
      )}

      {isOpenSendSuccesRequestModal && (
        <RequestSendSuccessModal
          open={isOpenSendSuccesRequestModal}
          setOpen={setIsOpenSendSuccesRequestModal}
          t={t}
        />
      )}
    </WrapperPage>
  );
};
