import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`;

export const ListItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  height: 80px;

  @media (max-width: 480px) {
    height: fit-content;
    flex-direction: column;
    gap: 5px;
  }
`;

export const Button = styled.button`
  max-width: 300px;
  width: 100%;
  background-color: #d53939;
  cursor: pointer;
  color: #f6f6f6;
  border-radius: 14px;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0%;
  height: 100% !important;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 948px) {
    max-width: 120px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    max-width: none;
    padding: 12px;
    font-size: 14px;
  }
`;

export const ContentInvoice = styled.div`
  border: 1px solid var(--Red, #d53939);
  padding: 10px 15px;
  background-color: #242424;
  width: 100%;
  border-radius: 20px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 110.00000000000001%;
  letter-spacing: 0%;
  color: #d53939;
  margin: 0;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const PlanName = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 110.00000000000001%;
  letter-spacing: 0%;
  color: #d53939;
  margin: 0;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;
